export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Admin',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const AUCTION_TYPE_CODE = {
  TLP: 'TLP',
  MAX: 'MAX',
  MIN: 'MIN',
};

export const rootUrl = '/';
export const adminRoot = '/app';
export const auctionAppUrl = process.env.REACT_APP_AUCTION_APP_URL;
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'dark.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const TOOL_TIP_TEXT = {
  COPY: 'Copy',
  COPIED: 'Copied',
};

export const ENGLISH_AUCTION_CATEGORY_OPTIONS = [
  {
    label: 'Highest',
    value: 'highest',
  },
  {
    label: 'Lowest',
    value: 'lowest',
  },
];

export const ENGLISH_AUCTION_TYPE_OPTIONS = [
  {
    label: 'Open',
    value: 'open',
  },
  {
    label: 'Sealed',
    value: 'sealed',
  },
];

export const ENGLISH_AUCTION_END_TYPE_OPTIONS = [
  {
    label: 'Duration',
    value: 'duration',
  },
  {
    label: 'Total Bids',
    value: 'total_bids',
  },
];
